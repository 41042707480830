<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ml-5">
        <v-btn @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>Назад</v-btn
        >
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-card width="1000" class="pa-3">
        <div class="d-flex grow flex-wrap">
          <v-sheet
            elevation="6"
            width="100%"
            height="100"
            class="mb-n6 success pa-7 has_heading"
          >
            <div class="text-h5 font-weight-light white--text">
              Добавление тарифа
            </div>
            <div class="subtitle-1 font-weight-light white--text">
              * помечены обязательные поля
            </div>
          </v-sheet>
        </div>
        <v-form ref="form" v-model="valid" @submit.prevent="">
          <v-container fluid>
            <v-col cols="">
              <v-text-field
                v-model="name"
                label="Название тарифа*"
                hint="Название позволяют отличать тарифы"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <!--            <v-col>-->
            <!--              <v-text-field-->
            <!--                v-mask="timeRangeMask"-->
            <!--                v-model="currentValue"-->
            <!--                @input="handleInput"-->
            <!--                placeholder="00:00-23:59"-->
            <!--              />-->
            <!--            </v-col>-->
            <v-col cols="">
              <v-text-field
                v-model="operates_from"
                label="Время начала действия*"
                v-mask="timeRangeMask"
                placeholder="00:00-23:59"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="operates_to"
                v-mask="timeRangeMask"
                placeholder="00:00-23:59"
                :rules="[required]"
                label="Время окончания действия*"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model.number="cost"
                label="Цена за квт по тарифу*"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-btn type="submit" block @click="create_tariff" color="primary"
                >Создать</v-btn
              >
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export function timeMask(value) {
  const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  return value.length > 2 ? [...hours, ":", ...minutes] : hours;
}

export function timeRangeMask(value) {
  const numbers = value.replace(/[^0-9]/g, "");
  if (numbers.length > 4) {
    return [...timeMask(numbers.substring(0, 4))];
  }
  return [...timeMask(numbers)];
}

export default {
  name: "Create",
  data: () => {
    return {
      valid: true,
      name: "",
      cost: "",
      operates_from: "",
      operates_to: "",
      timeRangeMask,
      currentValue: "",
      time_mask: [/[0-2][0-3]:[0-5][0-9]/],
      required: (v) => !!v || "Обязателено для заполнения",
    };
  },
  computed: {},
  methods: {
    validateField() {
      this.$refs.form.validate();
    },

    create_tariff() {
      this.validateField();
      if (!this.valid) {
        return;
      }

      const tariff = {
        name: this.name,
        cost: this.cost,
        operates_from: this.operates_from,
        operates_to: this.operates_to,
      };

      this.$store.dispatch("create_tariff", tariff).then(() => {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.$router.push({ name: "list_tariff" });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.has_heading {
  position: relative;
  top: -35px;
}
</style>
